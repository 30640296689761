import { render, staticRenderFns } from "./testEcharts-sandiantu.vue?vue&type=template&id=3b97d953&scoped=true&"
import script from "./testEcharts-sandiantu.vue?vue&type=script&lang=js&"
export * from "./testEcharts-sandiantu.vue?vue&type=script&lang=js&"
import style0 from "./testEcharts-sandiantu.vue?vue&type=style&index=0&id=3b97d953&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b97d953",
  null
  
)

export default component.exports