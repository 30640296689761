<template>
  <div style="display: flex;flex-wrap: wrap;">
    <div class="study_top_con_span" v-for="(item, index) in Overviewdata">
      <div class="study_top_con_span_div1">
        {{ item.name }}
      </div>
      <div class="study_top_con_span_div2">
        <span>{{ item.value }}</span>
        <img :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['Overviewdata'],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style>
.study_top_con_span {
  width: 300px;
  margin-bottom: 20px;
}
</style>
