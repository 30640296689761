<template>
  <div class="EchartsSandiantu-wrap">
    <div class="EchartsSandiantu">
      <testEchartsSandiantu
        :endTime="endTime"
        :startTime="startTime"
        v-for="item in change_student_list"
        :key="item.id"
        style="width: 50%; padding: 20px;"
        :studentInfo="item"
      ></testEchartsSandiantu>
    </div>
    <!-- //668 149 -->
    <el-button class="studentListShow" @click="drawer = !drawer" type="primary">
      学生列表
    </el-button>
    <div v-if="drawer" class="right-studentList">
      <div style="height:50px ; font-size:24px">学生列表：</div>
      <div class="studentBox">
        <div
          class="student"
          v-for="(item, index) in corrected_users"
          :key="index"
          @click="changeStudent(item, index)"
          :style="{ color: item.bcg ? '#fff' : '' }"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="rightInput">
        <!-- <span>最多选择学生数量：</span> -->
        <el-input v-model="keyWord" clearable> </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import testEchartsSandiantu from './Ceshi/testEcharts-sandiantu.vue';
import { getCorrectionFromPlan } from '../api/Correct.js';

export default {
  props: ['classStudent', 'startTime', 'endTime'],
  name: '',
  components: {
    testEchartsSandiantu,
  },
  data() {
    return {
      class_id: localStorage.getItem('teachclass_id')
        ? JSON.parse(localStorage.getItem('teachclass_id'))
        : this.$store.state.teachclass.id,
      corrected_users: [], //已批改
      change_student_list: [],
      // list: [
      //   {
      //     name: 'zdx',
      //     age: 11
      //   }, {
      //     name: 'www',
      //     age: 12
      //   },
      //   {
      //     name: 'ddd',
      //     age: 14
      //   }, {
      //     name: 'rrr',
      //     age: 13
      //   }
      // ],
      drawer: true,
      keyWord: 2,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
    // this.get_correction_from_plan();
  },

  methods: {
    get_correction_from_plan() {
      let that = this;
      getCorrectionFromPlan({
        plan_id: JSON.parse(localStorage.getItem('teachplan_id')),
        userID: JSON.parse(localStorage.getItem('teachuserID')),
        setId: JSON.parse(localStorage.getItem('teachsetId')),
        class_id: that.class_id,
      }).then((response) => {
        localStorage.setItem(
          'teachpageIds',
          JSON.stringify(response.data.pageIds)
        );
      });
    },
    init() {
      let that = this;
      //已批改
      this.corrected_users = this.classStudent;
      // this.pageIds = response.data.pageIds;
      //进入默认选择1到2个学生

      if (that.corrected_users.length == 1) {
        that.corrected_users[0].bcg = true;
        that.change_student_list.push(that.corrected_users[0]);
      } else if (that.corrected_users.length >= 2) {
        that.corrected_users[0].bcg = true;
        that.corrected_users[1].bcg = true;
        that.change_student_list.push(
          that.corrected_users[0],
          that.corrected_users[1]
        );
      } else {
        that.change_student_list = [];
      }
    },
    changeStudent(item, index) {
      let studentIndex = this.change_student_list.findIndex(
        (itemStudent) => item.id == itemStudent.id
      );
      if (studentIndex !== -1) {
        item.bcg = false;
        this.change_student_list.splice(studentIndex, 1);
        console.log(this.change_student_list);
      } else if (this.change_student_list.length >= this.keyWord) {
        this.change_student_list[0].bcg = false;
        this.change_student_list.shift();
        item.bcg = true;
        this.change_student_list.push(item);
        console.log(this.change_student_list);
      } else {
        item.bcg = true;
        this.change_student_list.push(item);
        console.log(this.change_student_list);
      }
    },
  },
};
</script>

<style scoped>
.active1 {
  color: #fff;
}

.active2 {
  color: #8d4141;
}

.studentBox {
  display: flex;
  flex-wrap: wrap;
}

.student {
  border: 1px solid #fff;
  border-radius: 16px;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px !important;
  padding: 2px;
}

.EchartsSandiantu-wrap {
  position: relative;
}

.EchartsSandiantu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.studentListShow {
  position: absolute;
  right: 0;
  bottom: 10%;
  height: 40px;
  border: 1px solid #ccc;
  background-color: #8d4141;
  z-index: 21;
}

.right-studentList {
  border-radius: 16px;
  position: absolute;
  right: 8%;
  bottom: 10%;
  height: auto;
  width: 668px;
  border: 1px solid #fff;
  color: #8d4141;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  z-index: 21;
}

.rightInput {
  width: 80px;
  height: 48px;
  position: absolute;
  right: 10%;
  top: 18px;
}

::v-deep .el-tabs__item {
  /* 改个标签颜色 */
  color: #8d4141;
}

::v-deep .is-active {
  /* 改个激活标签颜色 */
  color: #fff;
}
</style>
