<template>
  <div class="echartsBox">
    <div class="studentName">
      <el-tag v-if="singlePass">{{ singlePass.name }}</el-tag>
      <el-tag v-else>{{ studentInfo.name }}</el-tag>
    </div>

    <div
      v-if="echartsShow"
      :id="singlePass ? singlePass.id : studentInfo.id"
      style="width: 80%;height:400px;"
    ></div>
    <div style="width: 80%;height:400px;" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
import { getDotsInfor } from '../../api/Explain.js';
export default {
  props: ['studentInfo', 'startTime', 'endTime', 'singlePass'],
  name: '',
  components: {},
  data() {
    return {
      data3: [],
      echartsShow: true,
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (this.singlePass) {
      //点击警告学生列表的弹框
      this.singleEcharts();
    } else {
      this.initEcharts();
    }
  },
  methods: {
    singleEcharts() {
      let that = this;
      let data;

      data = {
        infor: {
          server_time: [
            102068,
            103808,
            103975,
            104418,
            113544,
            137101,
            174077,
            175312,
            196818,
            203991,
            207732,
            238642,
            280165,
            282281,
            297813,
            307103,
            329063,
            344225,
            346245,
            351554,
            351961,
            377017,
            378064,
            397067,
            399547,
            411597,
            412036,
            453323,
            483426,
            492084,
            496537,
            510575,
            561749,
            614269,
            615298,
            643680,
            679195,
            699688,
            708236,
            708448,
            734111,
            734868,
            740405,
            747925,
            1004356,
            1004961,
            1015201,
            1037486,
            1047165,
            1055529,
            1084031,
            1091531,
            1093282,
            1160599,
            1174670,
            1175187,
            1189276,
          ],
          force: [
            324,
            315,
            308,
            312,
            338,
            285,
            306,
            512,
            484,
            480,
            493,
            553,
            536,
            547,
            514,
            548,
            503,
            541,
            525,
            511,
            496,
            462,
            486,
            412,
            371,
            342,
            334,
            282,
            280,
            327,
            304,
            348,
            336,
            284,
            319,
            332,
            337,
            289,
            320,
            325,
            286,
            315,
            300,
            286,
            336,
            296,
            313,
            339,
            293,
            343,
            308,
            337,
            307,
            312,
            328,
            333,
            306,
          ],
        },
        dots_number: 56,
        // beginTime: 1672534806458,
      };

      // data.infor.server_time.forEach((item, index, arrData) => {
      //   arrData[index] = arrData[index] - data.beginTime;
      // });
      var myChart = echarts.init(
        document.getElementById(`${that.singlePass.id}`)
      );
      //ypen_time数据处理
      //X轴
      let dataX = [];
      //先清空data3  series
      this.data3 = [];
      for (let i = 1; i <= data.dots_number; i++) {
        dataX.push(i);
      }
      //series
      var dataY = {};
      for (let inforKey in data.infor) {
        dataY = {
          name: `${inforKey == 'server_time' ? '书写时长' : '书写压力'}`,
          type: 'line',
          data: data.infor[inforKey],
          markPoint: {
            //图标标注
            data: [
              { type: 'max', name: '最大值' },
              { type: 'min', name: '最小值' },
            ],
          },
        };
        this.data3.push(dataY);
      }

      // 基于准备好的dom，初始化echarts实例

      // 指定图表的配置项和数据
      let option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          feature: {
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {},
          },
        },
        legend: {
          show: 'true',
        },
        toolbox: {
          show: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dataX,
        },
        yAxis: {
          // type: 'value',
        },
        dataZoom: [
          {
            //内部缩放
            type: 'inside',
          },
        ],

        series: this.data3,
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initEcharts() {
      let that = this;
      that.echartsShow = true;
      //凑参数获取压力值
      let pressureParams = {
        data: {
          beginTime: that.startTime,
          endTime: that.endTime,
          penMac: that.studentInfo.penMac,
          startPageId: 0,
          endPageId: 0,
          startX: 0,
          startY: 0,
          endX: 200,
          endY: 250,
        },
      };
      getDotsInfor(pressureParams)
        .then((res) => {
          let data = res.data;
          //删除pen_time字段
          delete data.infor.pen_time;
          data.infor.server_time.forEach((item, index, arrData) => {
            arrData[index] = arrData[index] - data.beginTime;
          });
          var myChart = echarts.init(
            document.getElementById(`${that.studentInfo.id}`)
          );
          //ypen_time数据处理
          //X轴
          let dataX = [];
          //先清空data3  series
          this.data3 = [];
          for (let i = 1; i <= data.dots_number; i++) {
            dataX.push(i);
          }
          //series
          var dataY = {};
          for (let inforKey in data.infor) {
            dataY = {
              name: `${inforKey == 'server_time' ? '书写时长' : '书写压力'}`,
              type: 'line',
              data: data.infor[inforKey],
              markPoint: {
                //图标标注
                data: [
                  { type: 'max', name: '最大值' },
                  { type: 'min', name: '最小值' },
                ],
              },
            };
            this.data3.push(dataY);
          }

          // 基于准备好的dom，初始化echarts实例

          // 指定图表的配置项和数据
          let option = {
            title: {
              text: '',
            },
            tooltip: {
              trigger: 'axis',
              feature: {
                dataView: { readOnly: false },
                magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {},
              },
            },
            legend: {
              show: 'true',
            },
            toolbox: {
              show: true,
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: dataX,
            },
            yAxis: {},
            dataZoom: [
              {
                //底下显示  start和end要保持一致
                show: true,
                start: 50,
                end: 100,
              },
              {
                //内部缩放
                type: 'inside',
                start: 50,
                end: 100,
              },
            ],

            series: this.data3,
          };

          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        })
        .catch(() => {
          that.echartsShow = false;
        });
    },
  },
};
</script>

<style scoped>
.echartsBox {
  border: 1px solid #ccc;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.studentName {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
