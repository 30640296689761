<template>
  <div class="study_con_zuoye container" id="container">
    <div class="study_con_top">
      <div class="study_con_top_left">
        <!-- 高频错题的四个标签 张东旭要改的BUG1 -->
        <button
          @click="getitems()"
          class="basebtn"
          :class="[studybut == '1' ? 'studybutclass' : '']"
        >
          {{ $store.state.teachlanguagedata.frequencywrong }}
        </button>
        <button
          @click="getitems2()"
          class="basebtn"
          :class="[studybut == '2' ? 'studybutclass' : '']"
        >
          {{ $store.state.teachlanguagedata.knowledge }}
        </button>
        <button
          v-if="analyticMethodShow"
          @click="getitems3('analyticMethod')"
          class="basebtn"
          :class="[studybut == '4' ? 'studybutclass' : '']"
        >
          {{ $store.state.teachlanguagedata.problemSolvingProcess }}
        </button>
        <button
          v-if="thinkModelShow"
          @click="getitems3('thinkModel')"
          class="basebtn"
          :class="[studybut == '3' ? 'studybutclass' : '']"
        >
          {{ $store.state.teachlanguagedata.thinkingModel }}
        </button>
      </div>
      <div class="study_con_top_right">
        <span></span>
        <span>0 &lt; {{ $store.state.teachlanguagedata.correct }} ≤ 30%</span>
        <span></span>
        <span>30% &lt; {{ $store.state.teachlanguagedata.correct }} ≤ 60%</span>
        <span></span>
        <span>60 &lt; {{ $store.state.teachlanguagedata.correct }} ≤ 100%</span>
      </div>
    </div>
    <!-- 高频错题 -->
    <div class="study_con_con" v-if="studybut == 1">
      <el-table
        :data="Reportdata"
        style="width: 100%"
        row-key="id"
        :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }"
        :header-cell-style="{ background: '#F3F5F9', color: '#606266' }"
        :default-sort="{ prop: 'right_rate', order: 'descending' }"
      >
        <el-table-column
          prop="questionOutVo.index"
          :label="$store.state.teachlanguagedata.serialnumber"
        >
          <template slot-scope="scope">
            {{
              scope.row.questionOutVo && scope.row.questionOutVo.index
                ? scope.row.questionOutVo.index
                : scope.$index + 1
            }}
          </template>
        </el-table-column>
        <el-table-column :label="$store.state.teachlanguagedata.topicname">
          <template slot-scope="scope">
            <div
              class="timuclass"
              v-if="scope.row.questionOutVo && scope.row.questionOutVo.content"
              :class="
                subjectTitleId == scope.row.id
                  ? 'subjectTitle1'
                  : 'subjectTitle'
              "
              @click="subjectTitleClick(scope.row)"
              v-html="scope.row.questionOutVo.content"
            ></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="right_rate"
          :label="$store.state.teachlanguagedata.correct"
          sortable
          :sort-method="sortByVesselName"
        >
          <!-- <el-table-column prop="right_rate" label="正确率" sortable> -->
          <template slot-scope="scope">
            <!-- 悬浮 -->
            <el-popover
              placement="bottom"
              width="100"
              trigger="click"
              popper-class="my-popover"
            >
              <div
                v-if="
                  scope.row.right_students &&
                    scope.row.right_students.length > 0
                "
              >
                {{ $store.state.teachlanguagedata.correctstudent }}
                <div style="margin-left: 20px;">
                  <span v-for="item in scope.row.right_students">
                    <span v-if="Reportdatashow">{{ item.name }}</span>
                    <span v-else>{{ item }}</span>
                  </span>
                </div>
              </div>
              <div
                v-if="
                  scope.row.wrong_students &&
                    scope.row.wrong_students.length > 0
                "
              >
                {{ $store.state.teachlanguagedata.incorrectstudent }}
                <div style="margin-left: 20px;">
                  <span v-for="item in scope.row.wrong_students">
                    <span v-if="Reportdatashow">{{ item.name }}</span>
                    <span v-else>{{ item }}</span>
                  </span>
                </div>
              </div>
              <div
                v-if="
                  scope.row.uncorrect_students &&
                    scope.row.uncorrect_students.length > 0
                "
              >
                {{ $store.state.teachlanguagedata.Uncorrectedstudents }}
                <div style="margin-left: 20px;">
                  <span v-for="item in scope.row.uncorrect_students">
                    <span v-if="Reportdatashow">{{ item.name }}</span>
                    <span v-else>{{ item }}</span>
                  </span>
                </div>
              </div>
              <div
                v-if="
                  scope.row.unjoin_users && scope.row.unjoin_users.length > 0
                "
              >
                {{ $store.state.teachlanguagedata.unansweredstudents }}
                <div style="margin-left: 20px;">
                  <span v-for="item in scope.row.unjoin_users">
                    <span v-if="Reportdatashow">{{ item.name }}</span>
                    <span v-else>{{ item }}</span>
                  </span>
                </div>
              </div>
              <div slot="reference">
                <!-- percentage：进度条百分比 -->
                <el-progress
                  v-if="Reportdatashow"
                  :percentage="filterData(scope.row.right_rate)"
                  :color="customColorMethod"
                >
                </el-progress>
                <el-progress
                  v-else
                  :percentage="scope.row.right_rate"
                  :color="customColorMethod"
                >
                </el-progress>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="submit_student_number"
          :label="$store.state.teachlanguagedata.numberofcorrectquestions"
        >
          <template slot-scope="scope">
            <span
              style="padding: 10px;display: inline-block;"
              @click="StudentTime(scope.row)"
              >{{ scope.row.submit_student_number }}</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="submit_student_number" label="平均用时">
					<template slot-scope="scope" >
						<span style="padding: 10px;display: inline-block;" @click="StudentTime(scope.row)">30</span>
					</template>
				</el-table-column> -->
        <el-table-column :label="$store.state.teachlanguagedata.explain">
          <template slot-scope="scope">
            <el-button @click="getNav(scope.row, 'question')"
              >{{ $store.state.teachlanguagedata.explain }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 知识点 -->
    <div class="study_con_con" v-if="studybut == 2">
      <el-table
        :data="Reportdatas"
        style="width: 100%"
        row-key="id"
        :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }"
        :header-cell-style="{ background: '#F3F5F9', color: '#606266' }"
        :default-sort="{ prop: 'right_rate', order: 'descending' }"
      >
        <el-table-column :label="$store.state.teachlanguagedata.serialnumber">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$store.state.teachlanguagedata.knowledge"
        >
        </el-table-column>
        <el-table-column
          v-if="!Reportdatashow"
          prop="passRate"
          :label="$store.state.teachlanguagedata.Accuracy"
        >
          <template slot-scope="scope">
            {{ parseInt(scope.row.passRate * 100) }}%
          </template>
        </el-table-column>
        <el-table-column
          :label="$store.state.teachlanguagedata.explain"
          width="100"
        >
          <template slot-scope="scope">
            <el-button @click="getNav(scope.row, 'keypoint')"
              >{{ $store.state.teachlanguagedata.explain }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 解题方法 -->
    <div class="study_con_con" v-if="studybut == 3">
      <el-table
        :data="Reportdatas"
        style="width: 100%"
        row-key="id"
        :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }"
        :header-cell-style="{ background: '#F3F5F9', color: '#606266' }"
        :default-sort="{ prop: 'right_rate', order: 'descending' }"
      >
        <el-table-column :label="$store.state.teachlanguagedata.serialnumber">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$store.state.teachlanguagedata.thinkingModel"
        >
        </el-table-column>
        <el-table-column
          v-if="!Reportdatashow"
          prop="passRate"
          :label="$store.state.teachlanguagedata.Accuracy"
        >
          <template slot-scope="scope">
            {{ parseInt(scope.row.passRate * 100) }}%
          </template>
        </el-table-column>
        <el-table-column
          :label="$store.state.teachlanguagedata.explain"
          width="100"
        >
          <template slot-scope="scope">
            <el-button @click="getNav(scope.row, 'thinkModel')"
              >{{ $store.state.teachlanguagedata.explain }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 思维模型 -->
    <div class="study_con_con" v-if="studybut == 4">
      <el-table
        :data="Reportdatas"
        style="width: 100%"
        row-key="id"
        :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }"
        :header-cell-style="{ background: '#F3F5F9', color: '#606266' }"
        :default-sort="{ prop: 'right_rate', order: 'descending' }"
      >
        <el-table-column :label="$store.state.teachlanguagedata.serialnumber">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$store.state.teachlanguagedata.problemSolvingProcess"
        >
        </el-table-column>
        <el-table-column
          v-if="!Reportdatashow"
          prop="passRate"
          :label="$store.state.teachlanguagedata.Accuracy"
        >
          <template slot-scope="scope">
            {{ parseInt(scope.row.passRate * 100) }}%
          </template>
        </el-table-column>
        <el-table-column
          :label="$store.state.teachlanguagedata.explain"
          width="100"
        >
          <template slot-scope="scope">
            <el-button @click="getNav(scope.row, 'analyticMethod')">
              {{ $store.state.teachlanguagedata.explain }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 答题时间 -->
    <el-dialog :visible.sync="StudentTimeShow" width="30%">
      <el-table :data="StudentTimeList">
        <el-table-column
          type="index"
          :label="$store.state.teachlanguagedata.ranking"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$store.state.teachlanguagedata.name"
        >
        </el-table-column>
        <el-table-column prop="spendTime" label="学生用时"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryPlanCatalogDetailList,
  queryChapterCatalogDetailList,
  queryChapterLabelDetailList,
  queryPlanLabelDetailList,
  queryPlanQuestionUserSpendTimes,
  gotUserJoinAllStation,
  queryQuestionBaseTreeList,
} from '../../api/Explain.js';
import { showLoading, hideLoading } from '../../utils/Loading';
import baseJs from '../../utils/base/base.js';
export default {
  name: 'Study',
  props: ['Reportdata', 'plan_id'],

  watch: {
    studybut(vla) {
      this.Mathqunati();
    },
    Reportdata(vla) {
      // // 正确率保留两位小数
      // this.Reportdata.forEach((item,index,arr)=>{
      // 	// arr[index].right_rate = item.right_rate.toFixed(2)
      // 	console.log(item.right_rate);
      // })
      // console.log(this.Reportdata);
      //
      this.Mathqunati();
    },
    plan_id(vla) {
      this.plan_id = vla;
    },
  },
  data() {
    return {
      StudentTimeList: [],
      StudentTimeShow: false,
      submit_student_number: 'submit_student_number',
      studybut: 1,
      subjectTitleId: '',
      Reportdatas: [],
      Reportdatashow: false,
      thinkModelShow: false,
      analyticMethodShow: false,
    };
  },
  created() {},
  mounted() {
    if (
      window.location.hash == '#/UserStudy' ||
      window.location.hash == '#/userStudy'
    ) {
      this.Reportdatas = this.Reportdata;
      //张东旭改的  进入学情 高频错题就加载
      // this.getitems()
      //
      this.Reportdatashow = true;
    } else {
      this.Reportdatashow = false;
      this.getitems();
    }
    // this.$parent.$refs.Nav_class.getjiacai()
    // 判断是否显示
    this.getitems3('analyticMethod');
    this.getitems3('thinkModel');
  },
  methods: {
    getitems3s() {
      this.getitems3('analyticMethod');
      this.getitems3('thinkModel');
    },
    //当前题用时
    //topicData 当前题参数
    StudentTime(topicData) {
      let data = {
        parameters: {
          classId: JSON.parse(localStorage.getItem('teachclass_id')),
          planId: localStorage.getItem('teachplan_id'),
          subId: topicData.id,
        },
      };
      queryPlanQuestionUserSpendTimes(data).then((res) => {
        this.StudentTimeList = res.data.result;
        if (res.data.result.length > 0) {
          this.StudentTimeShow = true;
        } else {
          this.$message('当前题尚未有人答题');
        }
      });
    },
    getitems3(status) {
      // 左边树型改的位置

      let data = {
        parameters: {
          classId: JSON.parse(localStorage.getItem('teachclass_id')),
          // "relationIds": localStorage.getItem('teachchapters').replace('[', "").replace(']', ""),
          // "relationIds":this.$store.state.userStudyChapters,
          relationIds:
            JSON.parse(localStorage.getItem('teachchapters')).length == 0
              ? sessionStorage.getItem('UserStudyChapters') == null
                ? JSON.stringify(
                    JSON.parse(localStorage.getItem('teachchaptersData')).id
                  )
                : sessionStorage.getItem('UserStudyChapters')
              : JSON.parse(localStorage.getItem('teachchapters')).toString(),
          subjectId: localStorage.getItem('teachsubjectId'),
          kind: status,
        },
      };
      // showLoading()
      if (this.Reportdatashow) {
        let that = this;
        queryChapterLabelDetailList(data)
          .then((res) => {
            hideLoading();
            this.Reportdatas = res.data.result;
            console.log(that.Reportdatas);
          })
          .catch((res) => {
            // hideLoading()
          });
      } else {
        delete data.parameters.relationIds;
        delete data.parameters.subjectId;
        data.parameters.planId = localStorage.getItem('teachplan_id');
        queryPlanLabelDetailList(data)
          .then((res) => {
            // hideLoading()
            if (res.data.result && res.data.result.length > 0) {
              this.Reportdatas = res.data.result;
              console.log(that.Reportdatas);
              if (status == 'thinkModel') {
                this.thinkModelShow = true;
              } else {
                this.analyticMethodShow = true;
              }
              if (status == 'thinkModel') {
                this.studybut = 3;
              } else {
                this.studybut = 4;
              }
            }
          })
          .catch((res) => {
            // hideLoading()
          });
      }
    },
    getNav(data, stutas) {
      let that = this;
      localStorage.setItem(
        'teachlupin',
        JSON.stringify({
          name: data,
          stutas: stutas,
        })
      );
      this.$router.push({
        name: 'Explains',
        params: {
          plan_id: that.plan_id,
        },
      });
    },
    Mathqunati() {
      this.$nextTick(function() {
        //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
        if (this.commonsVariable.isMathjaxConfig) {
          //判断是否初始配置，若无则配置。
          this.commonsVariable.initMathjaxConfig();
        }
        this.commonsVariable.MathQueue('container');
        this.Mathqunatishow = true;
      });
    },
    getitems() {
      this.studybut = 1;
      this.Reportdatas = [];
      // showLoading()
      if (
        window.location.hash == '#/UserStudy' ||
        window.location.hash == '#/userStudy'
      ) {
        this.$parent.getItemsforchapters();
      } else {
        let data = {
          planId: JSON.parse(localStorage.getItem('teachplan_id')),
          classId: JSON.parse(localStorage.getItem('teachclass_id')),
        };
        gotUserJoinAllStation(data).then((response) => {
          this.QueryQuestionBaseTreeList(response.data.result);
        });
      }
      //张东旭注掉的4月4日  以为它重复刷新
      this.Mathqunati();
    },
    QueryQuestionBaseTreeList(Adapter1Data) {
      let data = {
        setId: this.$Download.getQuestionSet(
          JSON.parse(localStorage.getItem('teachQuestionSetInformation'))
            .resourceRelationList,
          'questionSet'
        ),
      };
      queryQuestionBaseTreeList(data).then((response) => {
        this.studybut = 1;
        this.Reportdatas = baseJs.Adapter1(response.data.result, Adapter1Data);
        // hideLoading()
      });
    },
    getitems2() {
      this.studybut = 2;
      this.Reportdatas = [];
      // showLoading()
      if (this.Reportdatashow) {
        queryChapterCatalogDetailList({
          parameters: {
            classId: JSON.parse(localStorage.getItem('teachclass_id')),
            // "relationIds": localStorage.getItem('teachchapters').replace('[', "").replace(']', ""),
            relationIds:
              JSON.parse(localStorage.getItem('teachchapters')).length == 0
                ? sessionStorage.getItem('UserStudyChapters') == null
                  ? JSON.stringify(
                      JSON.parse(localStorage.getItem('teachchaptersData')).id
                    )
                  : sessionStorage.getItem('UserStudyChapters')
                : JSON.parse(localStorage.getItem('teachchapters')).toString(),
            subjectId: JSON.parse(localStorage.getItem('teachsubjectId')),
          },
        })
          .then((response) => {
            this.Reportdatas = response.data.result;
            // hideLoading()
          })
          .catch((res) => {
            // hideLoading()
          });
      } else {
        queryPlanCatalogDetailList({
          parameters: {
            classId: JSON.parse(localStorage.getItem('teachclass_id')),
            planId: localStorage.getItem('teachplan_id'),
            kind: 'keyPoint',
          },
        })
          .then((response) => {
            this.Reportdatas = response.data.result;
            // hideLoading()
          })
          .catch((res) => {
            // hideLoading()
          });
      }
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#4CAAFF';
      } else if (percentage < 60) {
        return ' #FF8B00';
      } else {
        return '#FF5630';
      }
    },
    filterData(data) {
      if (data) {
        var j = data.substring(0, data.indexOf('.')) * 1;
        var i = data.substring(data.indexOf('.') + 1, data.indexOf('.') + 2);
        if (i > 4) {
          j = j + 1;
        }
        if (j > 0) {
          return j;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    // 排序
    sortByVesselName(obj1, obj2) {
      let return1 = obj1.right_rate ? obj1.right_rate : 0;
      let return2 = obj2.right_rate ? obj2.right_rate : 0;
      return return1 - return2;
    },
    subjectTitleClick(data) {
      if (this.subjectTitleId == data.id) {
        this.subjectTitleId = '';
      } else {
        this.subjectTitleId = data.id;
      }
    },
  },
};
</script>

<style lang="scss">
.study_con_zuoye {
  width: 100%;

  .study_con_top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .study_con_top_left {
      button {
        height: 32px;
      }

      margin-bottom: 20px;
    }

    .study_con_top_right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      span:nth-child(odd) {
        width: 11px;
        height: 12px;
        display: inline-block;
        margin: 0 10px;
      }

      span:nth-child(1) {
        background: #4caaff;
      }

      span:nth-child(3) {
        background: #ff8b00;
      }

      span:nth-child(5) {
        background: #ff5630;
      }
    }
  }

  .study_con_con {
    margin-top: 12px;
    max-height: 52vh;
    overflow: auto;

    .subjectTitle {
      margin: 0;
      white-space: nowrap;

      p:not(:first-child) {
        display: none;
      }
    }

    .subjectTitle img {
      max-width: 100px;
    }

    .subjectTitle1 img {
      max-width: 100%;
    }
  }
}
</style>
